import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { setLoginMethod, resetLoginMethod, hideAllPanels } from '../../../../../redux/actions';

function RegisterGoogle() {  
  const dispatch = useDispatch();

  return (
    <>
        <div className="">
        </div>
    </>
  );
}

export default RegisterGoogle;