export const footerHeadings = {
    companyInfo: {
        title: "Face Matte",
        description: "Expert Solutions for Flawless Video Rendering.",
    },
    contact: {
        title: "Contact Us",
        contacts: [
            { name: "Email", value: "support@facematte.com" },
            { name: "Phone", value: "+1 234 567 8901" },
            { name: "Address", value: "1234 Film Street, Hollywood, CA 90001, USA" },
        ],
    },
    quickLinks: {
        title: "Quick Links",
        links: [
            { name: "Upload", href: "#upload" },
            // { name: "Examples", href: "#examples" },
            { name: "Pricing", href: "#pricing" },
            { name: "FAQ", href: "#faq" },
            { name: "Contact", href: "#contact" },
            { name: "Privacy Policy", href: "#privacy-policy" },
        ],
    },
    copyright: {
        text: "© 2024 Face Matte. All rights reserved.",
        link: {
            href: "https://facematte.com",
            name: "facematte.com",
        },
    },
};

export const componentsHeadings = {
    HeroSection: {
        title: "Remove Black Frames.<br />ANY Resolution. ANY Codec.",
        subtitle: "Last-minute lifesaver for your footage. Eliminate black or missing frames seamlessly, even if you're working outside the studio without After Effects, DaVinci Resolve, Blackmagic setup or any video editing software. "
    },
    UVPSection: {
        title: "Your Go-To Solution for Flawless Video Rendering",
        subtitle: "We provide a specialized service to fix black frame errors and rendering issues in your videos. Whether you're facing urgent deadlines or routine tasks, we ensure your footage looks perfect every time."
    },
    BenefitsSection: {
        title: "Why Choose Our Service?",
        icon1: "fas fa-video",
        title1: "Comprehensive Frame Repair",
        subtitle1: "No matter the resolution or codec, we handle all types of footage to remove black frames and ensure smooth playback.",
        icon2: "fas fa-clock",
        title2: "Fast Turnaround",
        subtitle2: "Get your footage fixed quickly without compromising on quality, even on tight deadlines.",
        icon3: "fas fa-dollar-sign",
        title3: "Cost-Effective Solutions",
        subtitle3: "Affordable pricing without hidden fees. Pay only for the service you need.",
        icon4: "fas fa-shield-alt",
        title4: "Reliable and Secure",
        subtitle4: "Your footage is handled with the utmost care and confidentiality, ensuring a secure and trustworthy service."
    },
    HowItWorksSection: {
        title: "How It Works",
        icon1: "fas fa-globe",
        title1: "Select Service",
        subtitle1: "Choose the type of service you need, from renovations to repairs.",
        icon2: "fas fa-search",
        title2: "Enter Your Address",
        subtitle2: "Provide the location where you need the service. Our system will find the closest professionals to you.",
        icon3: "fas fa-phone",
        title3: "Compare and Choose",
        subtitle3: "View options, compare quotes and reviews, and select the right professional for you.",
        icon4: "fas fa-lock",
        title4: "Enjoy the Service",
        subtitle4: "The job is done! Relax while our experts take care of everything."
    },
    SocialProofSection: {
        title: "What Our Clients Say",
        testimonials: [
            { name: 'Marco Rossi', role: 'Homeowner', content: 'Excellent service! They transformed my old house into a modern dream.' },
            { name: 'Laura Bianchi', role: 'Entrepreneur', content: 'Impeccable professionalism and punctuality. My new office is perfect.' },
            { name: 'Giovanni Verdi', role: 'Architect', content: 'Working with them was a pleasure. They execute projects with precision and care.' },
        ]
    },
    CallToActionSection: {
        title: "Ready to Get Started?",
        subtitle: "Drop your footage and we will drop your frames!",
        button: "Get rid of black frames now"
    },
    ContactSection: {
        title: "Contact Us",
        subtitle: "We’re here to assist you. Fill out the form below, and we’ll get back to you as soon as possible.",
        name: "Name",
        email: "E-mail",
        subject: "Subject",
        message: "Your message goes here...",
        button: "Send request"
    },
    LoginSection: {
        title: `Welcome to ${footerHeadings.companyInfo.title}`,
        subtitle: "Your Go-To Solution for Flawless Video Rendering"
    },
};

export const appearanceScheme = {
    colors: {
      // Define custom palette
      palette: {
        'primary': '#87a330',
        'secondary': '#87a330',
        'alt': '#87a330',
        'gradientPrimary': '#ef4444', // '#cc6600'
        'gradientSecondary': '#b91c1c', // #ff66cc'
        'gradientLight': '#fee2e2',
      },
      // Define custom light mode colors
      lightTheme: {
        'primary': '#ffffff',
        'secondary': '#000000',
        'alt': '#ffffff',
        'separator' : '#ceced9',
        'text' : '#000000',
      },
      // Define custom dark mode colors
      darkTheme: {
        'primary': '#000000',
        'secondary': '#ffffff',
        'alt': '#323232',
        'separator' : '#3d3d40',
        'text' : '#ffffff',
      },
    },
    fontFamily: {
      // Define custom font family
      lightTheme: ['PT Mono, monospace'],
      darkTheme: ['PT Mono, monospace'],
    },
};

export default { componentsHeadings, footerHeadings, appearanceScheme };
