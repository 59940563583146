import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useAxios from '../../../utils/useAxios';
import { CloudArrowUpIcon, CloudArrowDownIcon, CheckCircleIcon, XCircleIcon, ForwardIcon, ArrowPathIcon } from '@heroicons/react/24/outline';

import Button from '../ui/button';

import CheckUploadPermission from './CheckUploadPermission';

import { StripePopupTrigger } from '../../../utils/StripePopup';

import jwtDecode from "jwt-decode"

const token = localStorage.getItem("authTokens")
        
if (token) {
  try {
    const decode = jwtDecode(token);
    // console.log("Decoded token:", decode); // Inspect the decoded token
    var user_id = decode.id;
    var username = decode.username;
    var email = decode.email;
  } catch (error) {
    console.error("Failed to decode token:", error);
  }
}

const VideoUpload = () => {
  const [video, setVideo] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0); // New state for upload progress
  const [videoId, setVideoId] = useState(null);
  const [downloadUrl, setDownloadUrl] = useState('');
  const [fileName, setFileName] = useState('');
  const [videoURL, setVideoURL] = useState('');

  const paymentUuid = useSelector((state) => state.upload.uploadUuid);

  const canUpload = useSelector((state) => state.upload.canUpload);

  const fileInputRef = useRef(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const axios = useAxios();

  const handleChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setVideo(file);
      setFileName(file.name);
      const videoUrl = URL.createObjectURL(file);
      setVideoURL(videoUrl);
    } else {
      setVideo(null);
      setFileName('');
      setVideoURL('');
    }
  };

  const handleRemoveFile = () => {
    setVideo(null);
    setFileName('');
    setVideoURL('');
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const checkStatus = async (videoId) => {
    const interval = setInterval(async () => {
      try {
        const response = await axios.get(`/status/${videoId}/`);
        const { status, processed_video_url } = response.data;

        if (status === 'done') {
          setDownloadUrl(`https://${process.env.REACT_APP_DJANGO_IP_REMOTE}:9777${processed_video_url}`);
          clearInterval(interval);
        } else if (status === 'failed') {
          clearInterval(interval);
        }

        setUploadStatus(status);

      } catch (error) {
        console.error(error);
        clearInterval(interval);
      }
    }, 1000);
  };

  const resetForm = () => {
    setVideo(null);
    setUploadStatus('');
    setUploadProgress(0);  // Reset upload progress
    setVideoId(null);
    setDownloadUrl('');
    setFileName('');
    setVideoURL('');
    setIsPopupOpen(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const generateThumbnails = () => {
    const videoElement = videoRef.current;
    const canvasElement = canvasRef.current;
    const ctx = canvasElement.getContext('2d');

    if (!videoElement || !ctx) return;

    const { duration, videoWidth, videoHeight } = videoElement;
    const numberOfThumbnails = 8;
    const thumbnailWidth = canvasElement.width / numberOfThumbnails;
    const thumbnailHeight = canvasElement.height;

    videoElement.currentTime = 0; // Start at the beginning

    const drawFrame = (i) => {
      if (i >= numberOfThumbnails) return; // Stop when all thumbnails are drawn

      videoElement.currentTime = (i / numberOfThumbnails) * duration;

      videoElement.onseeked = () => {
        ctx.drawImage(
          videoElement,
          i * thumbnailWidth, 0,
          thumbnailWidth, thumbnailHeight
        );
        drawFrame(i + 1); // Draw the next frame
      };
    };

    drawFrame(0); // Start drawing frames
  };

  useEffect(() => {
    if (videoURL && videoRef.current) {
      const videoElement = videoRef.current;
      const handleLoadedMetadata = () => {
        generateThumbnails();
      };

      videoElement.addEventListener('loadedmetadata', handleLoadedMetadata);

      return () => {
        videoElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
      };
    }
  }, [videoURL]);

  const handleMouseMove = (event) => {
    const canvasElement = canvasRef.current;
    const videoElement = videoRef.current;
    const timelineIndicator = document.getElementById('timeline-indicator');

    if (!canvasElement || !videoElement || !timelineIndicator) return;

    const rect = canvasElement.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const time = (x / canvasElement.width) * videoElement.duration;

    if (isFinite(time)) {
      timelineIndicator.style.left = `${x}px`; // Update the vertical bar position
    }
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  // Function to open the popup
  const openPopup = () => {
    setIsPopupOpen(true);
  };

  // Function to close the popup
  const closePopup = () => {
    setIsPopupOpen(false);
  };

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   if (!video) {
  //     setUploadStatus('empty');
  //     return;
  //   }

  //   const formData = new FormData();
  //   formData.append('video', video);

  //   try {
  //     setUploadStatus('uploading');  // Set status to uploading

  //     const response = await axios.post(`/upload-video/`, formData, {
  //       onUploadProgress: (progressEvent) => {
  //         const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
  //         setUploadProgress(progress);  // Update upload progress
  //       }
  //     });

  //     setUploadStatus('processing');
  //     setVideoId(response.data.video_id);
  //     checkStatus(response.data.video_id);
  //   } catch (error) {
  //     setUploadStatus('failed');
  //     console.error(error);
  //   }
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    if (!video) {
      setUploadStatus('empty');
      return;
    }
  
    // Open the payment popup first
    if ( !token ) {
      openPopup();
    } else {
      handlePaymentSuccess(); // Call the success handler or any other logic
    }
  };
  
  // New function to handle upload after payment success
  const handlePaymentSuccess = async () => {
    // console.log(paymentUuid);
    
    const formData = new FormData();
    formData.append('video', video);
    
    if ( paymentUuid == null ) {
      formData.append('paymentUuid', 'null');
    } else {
      formData.append('paymentUuid', paymentUuid);
    }
  
    try {
      setUploadStatus('uploading');  // Set status to uploading
  
      const response = await axios.post(`/upload-video/`, formData, {
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(progress);  // Update upload progress
        }
      });
  
      setUploadStatus('processing');
      setVideoId(response.data.video_id);
      checkStatus(response.data.video_id);
    } catch (error) {
      setUploadStatus('failed');
      console.error(error);
    }
  };

  // min-h-[400px] md:min-h-[400px] lg:min-h-[450px]

  return (
    <div className="flex flex-col items-center justify-center p-24">
      <div className="shadow-lg rounded-lg max-w-xl border-black dark:border-white border-x-[1px] border-y-[8px] min-w-[400px] md:min-w-[600px] lg:min-w-[800px]">
        <div className="flex flex-col items-center m-8">
          {uploadStatus === '' && (
            <div className="flex flex-col items-center ">
              <CheckUploadPermission />

              <div className="flex flex-col items-center mt-4">
                <h1 className="text-2xl font-semibold mt-6 text-center">Upload your video</h1>
                <span className="text-lg font-medium text-gray-800 dark:text-gray-200 mb-2 animate-pulse">Proceed to remove black frames.</span>

                <form onSubmit={handleSubmit} className="flex flex-col items-center w-full">
                  <label htmlFor="file-upload" className="flex flex-col items-center cursor-pointer my-2 mt-3">
                    <div className="flex flex-col items-center">
                      <CloudArrowUpIcon className="w-20 h-20 text-gray-400 transition-transform duration-500 transform hover:scale-110" />

                      {canUpload ? (
                        <>
                          <input
                              id="file-upload"
                              type="file"
                              accept="video/*"
                              onChange={handleChange}
                              className="hidden"
                              ref={fileInputRef}
                          />
                          <span className="text-sm font-medium text-gray-800 dark:text-gray-300">
                              {fileName ? 'Choose another file' : 'Choose a file'}
                          </span>                      
                        </>
                      ) : (
                          <span className="text-sm font-medium text-gray-800 dark:text-gray-300">
                              Upgrade your plan to upload more files
                          </span>
                      )}

                    </div>                  
                  </label>

                  {fileName && (
                      <>
                          <div className="my-6 flex flex-col items-center">
                              <span className="font-medium text-md text-gray-800 dark:text-gray-300">Selected file:</span>
                              <span className="font-bold text-lg text-gray-800 dark:text-gray-300">{fileName}</span>
                            <div className="mt-2 relative w-80">
                            {/* Hidden video element used for extracting frames */}
                              <video
                                  ref={videoRef}
                                  src={videoURL}
                                  className="hidden"
                                  controls={false}
                              ></video>

                              {/* Canvas for Thumbnails */}
                              <canvas
                                  ref={canvasRef}
                                  className="w-full h-20 cursor-pointer border border-black dark:border-white rounded-md"
                                  onMouseMove={handleMouseMove}
                              />

                              {/* Vertical bar for timeline indication */}
                              {/* <div
                                  id="timeline-indicator"
                                  className="absolute top-0 bottom-0 w-[2px] bg-palette-gradientPrimary opacity-75 pointer-events-none"
                                  style={{ left: 0 }}
                              ></div> */}

                              <button
                                  type="button"
                                  onClick={handleRemoveFile}
                                  className="absolute right-0 top-0 m-0.5 bg-gradient-to-br hover:bg-gradient-to-br from-palette-gradientPrimary to-palette-gradientSecondary hover:from-palette-gradientPrimary hover:to-palette-gradientPrimary text-sm font-bold py-1 px-3 rounded transition flex items-center text-white"
                              >
                                  Remove
                              </button>
                            </div>
                          </div>
                      </>
                  )}
                  {video && (
                  <Button 
                      type="submit" 
                      className="mt-4 bg-gray-200 text-black font-medium py-2 px-6 rounded hover:bg-white"
                      disabled={video === null}
                  >
                    Proceed
                  </Button>
                  )}

                </form>

                {!token && isPopupOpen && (
                  <StripePopupTrigger reason='generation' mode='payment' price_id='price_1Pv64fJ0wDsD9vPNMbmj3Kn9' quantity='1' onPaymentSuccess={handlePaymentSuccess} onClose={closePopup} />
                )}

              </div>
            </div>
          )}
          {uploadStatus === 'uploading' && (
            <div className="flex flex-col items-center my-8">
              <h1 className="text-2xl font-semibold mb-4 text-center">Uploading...</h1>
              <span className="text-lg font-medium text-black dark:text-white mb-4 animate-pulse">We are uploading the footage...</span>
              <CloudArrowUpIcon className="w-20 h-20 text-gray-400" />
              <div className="w-full bg-gray-200 rounded-full h-4 dark:bg-gray-700">
                <div
                  className="bg-gradient-to-br from-palette-gradientPrimary to-palette-gradientSecondary h-4 rounded-full"
                  style={{ width: `${uploadProgress}%` }}
                ></div>
              </div>
              <span className="text-lg font-medium text-black dark:text-white mt-2">{uploadProgress}%</span>
            </div>
          )}
          {uploadStatus === 'processing' && (
              <div className="flex flex-col items-center my-8">
                  <h1 className="text-2xl font-semibold mb-4 text-center">Processing...</h1>
                  <span className="text-lg font-medium text-gray-800 dark:text-gray-300 mb-4 animate-pulse">We are processing the footage...</span>
                  <ArrowPathIcon className="w-20 h-20 text-gray-400 animate-spin" />
              </div>
          )}
          {uploadStatus === 'done' && (
              <div className="flex flex-col items-center my-8">
                  <h1 className="text-3xl font-semibold mb-1 text-center">Task done.</h1>
                  <span className="text-lg font-medium text-gray-800 dark:text-gray-300 mb-2 animate-pulse">Your download is ready.</span>
                  {/* <CloudArrowDownIcon className="w-12 h-12 text-white" /> */}
                  {downloadUrl && (
                      <div className="flex flex-col items-center">

                        <Button 
                            className="mt-4 text-lightTheme-text font-medium py-2 px-6 rounded hover:bg-darkTheme-primary dark:hover:bg-lightTheme-primary hover:text-darkTheme-text dark:hover:text-lightTheme-text "
                        >
                            <a href={downloadUrl} download target="_blank" className="">
                                Download
                            </a>
                        </Button>

                        {/* <Button 
                            className="mt-4 bg-gray-200 text-black font-medium py-2 px-6 rounded hover:bg-white "
                            onClick={openPopup} // Trigger the popup on button click
                        >
                          Download
                        </Button> */}

                        {/* {isPopupOpen && <StripePopupRedirect downloadUrl={downloadUrl} onClose={closePopup} userId/>} */}
                        
                        <div className="flex flex-col items-center mt-6">
                            <span className="text-sm font-medium text-gray-800 dark:text-gray-300 mb-2">Need more footages?</span>
                            <Button 
                                onClick={resetForm}
                                className="py-2 px-6"
                            >
                                <div className="flex flex-col">
                                    <ForwardIcon className="w-5 h-5 mr-2" />
                                    Upload New Video
                                </div>
                            </Button>
                        </div>
                    </div>
                  )}
              </div>
          )}
          {uploadStatus === 'failed' && (
              <div className="flex flex-col items-center my-8">
                  <h1 className="text-2xl font-semibold mb-4 text-center">Processing error.</h1>
                  <XCircleIcon className="w-20 h-20 text-palette-gradientPrimary mb-2 animate-pulse" />
                  <span className="text-lg font-medium text-palette-gradientPrimary mb-8">Upload Failed. Please try again.</span>
                  <Button 
                      onClick={resetForm}
                      className="py-2 px-4"
                  >
                    Retry
                  </Button>
              </div>
          )}
        </div>
      </div>
      </div>
  );
};

export default VideoUpload;
